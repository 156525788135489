import { api } from "./axios";

const login = data => {
  console.log(data);
  return api.post("auth/login", data);
};
const logout = () => {
  return api.get("auth/logout");
};

export { login, logout };
