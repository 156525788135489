<!-- scss on _navbar.scss -->
<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top sticky-top border-bottom navbar-expand bg-white"
  >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <template v-for="(route, index) in routes">
          <li class="breadcrumb-item" :key="index" v-if="index === 0">
            <router-link :to="{ name: route.name }">{{ route.alias }}</router-link>
          </li>
          <li
            class="breadcrumb-item"
            :key="index"
            v-else-if="index + 1 === routes.length"
          >{{ route.alias }}</li>
          <li class="breadcrumb-item" :key="index" v-else>
            <router-link :to="{ name: route.name }">{{ route.alias }}</router-link>
          </li>
        </template>
      </ol>
    </nav>

    <!-- Navbar links -->
    <div class="ml-auto d-flex align-items-center">
      <ul class="navbar-nav align-items-center ml-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div class="pr-3 sidenav-toggler" @click="toggleSidebar">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav align-items-center ml-auto">
        <base-dropdown
          menu-on-right
          class="nav-item"
          tag="li"
          title-tag="a"
          title-classes="nav-link pr-0"
        >
          <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
            <div class="media align-items-center">
              <div class="media-body d-none d-lg-block user-account">
                <p class="mb-0 account-name">{{ getInfoAuth.name }}</p>
                <span class="account-status">{{ getInfoAuth.role === 1 ? 'Admin' : 'Trainer' }}</span>
              </div>

              <img
                class="profile"
                alt="Image placeholder"
                :src="`${getInfoAuth.image}?${new Date().getTime()}`"
              />
            </div>
          </a>

          <template>
            <!-- <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>-->
            <a class="dropdown-item" @click="handleLogout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </ul>
    </div>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import { mapActions, mapGetters } from 'vuex'
import { logout } from '@/services/auth'

export default {
  components: {
    BaseNav
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    },
    routes: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
    routeName() {
      const { name } = this.$route;
      return name.split(/(?=[A-Z])/).join(" ")
      // return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    ...mapActions('auth', ['removeAuth']),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async handleLogout() {
      try {
        await logout()
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Logout Success"
        });
        this.$cookies.remove('CERT');
        this.removeAuth()
        this.$router.push({ name: 'Login' });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.profile {
  width: 32px;
  height: 32px;
  margin-left: 5px;
  border-radius: 100px;
  box-shadow: 0 4px 2px 1px rgba(0, 0, 0, 0.4);
}
</style>
